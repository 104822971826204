.scene {
  /* z-index: 0; */
}

.bodyClass {
  overflow: hidden;
  position: relative;
  background-color: black;
  /* background-image: url("https://raw.githubusercontent.com/rainner/codepen-assets/master/images/pinkish_sunset.jpg"); */
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 170vh;
  font-family: "Denk One", Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.2em;
  color: #f0f0f0;
  width: 100vw;
  height: 100vh;
}

/* // webgl canvas */
#stageElement {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* // fixed sections */
.player,
.instructions {
  display: flex;
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.instructions > .fa {
  font-size: 320%;
  color: inherit;
}

.instructions > .content {
  margin-left: 1em;
}

/* // music player */
.player {
  left: 1em;
  bottom: 1em;
}
button {
  display: inline-block;
  background: none;
  border: 2px solid rgba(#fff, 0.8);
  font: inherit;
  font-size: 80%;
  line-height: 1.2em;
  color: inherit;
  margin: 2px 0;
  padding: 2px 6px;
  border-radius: 4px;
  cursor: pointer;
}
button:hover {
  border-color: rgba(#fff, 0.6);
}
/* // music player */
.instructions {
  right: 1em;
  bottom: 1em;
}
